$(window).scroll(function(){
    var sticky = $('.header__wrap');
    var burger = $('button.burger'),
        scroll = $(window).scrollTop();

    if (scroll >= 28) sticky.addClass('sizeh');
    else sticky.removeClass('sizeh');
    if (scroll >= 28) burger.addClass('burger_st');
    else burger.removeClass('burger_st');
});

$(window).ready(function () {

    $('#count_days').change(function() {
        var currentVal = parseInt($('#count_days').val());
        if (currentVal < 4)
        {
            $('.form__item .minus').addClass('disable-minus');
        }
        if (currentVal > 3) {
            $('.form__item .minus').removeClass('disable-minus');
        }
    });

    $('.form__item .minus').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;


    });
    $('.form__item .plus').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });

    $("button.burger").click(function(){
        $(this).toggleClass("active");
        $('.menu__wrap nav').toggleClass("active");
        $('body').toggleClass("over_hide");
    });
    $("nav ul li a").click(function(){
        $('button.burger').removeClass("active");
        $('.menu__wrap nav').removeClass("active");
        $('body').removeClass("over_hide");
    });

    $(".questions__item > a").on("click", function() {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            $(this)
                .siblings(".questions__desc")
                .slideUp(200);
            $(".questions__item > a i")
                .removeClass("fa-angle-up")
                .addClass("fa-angle-down");
        } else {
            $(".questions__item > a i")
                .removeClass("fa-angle-up")
                .addClass("fa-angle-down");
            $(this)
                .find("i")
                .removeClass("fa-angle-down")
                .addClass("fa-angle-up");
            $(".questions__item > a").removeClass("active");
            $(this).addClass("active");
            $(".questions__desc").slideUp(200);
            $(this)
                .siblings(".questions__desc")
                .slideDown(200);
        }
    });

    $('.first_foto').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.sm_foto',

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    fade: false,
                    arrows: true
                }
            }
        ]
    });
    $('.sm_foto').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.first_foto',
        dots: false,
        centerMode: true,
        focusOnSelect: true
    });
    $('.first_foto').slickLightbox({
        itemSelector: '.slick-slide > a'
    });
});

$(document).ready(function(){

    $('#datepicker').datepicker({
        autoClose: true,
        // Можно выбрать тольо даты, идущие за сегодняшним днем, включая сегодня
        minDate: new Date()
    })

    $('.phone_us').mask('+7 (000) 000-00-00');

    $('#btn_submit').click(function(){
        // собираем данные с формы
        var user_name 	    = $('#user_name').val();
        var user_phone 	    = $('#user_phone').val();
        var datepickerq     = $("#rd").text();
        var count_daysq     = $("#r1").text();
        var guest2q          = $("#r2").text();
        var gifts2q          = $("#r3").text();
        var taxi2q          = $("#r4").text();
        var grumm2q          = $("#r5").text();
        var fotovideo2q          = $("#r6").text();
        var resq          = $("#price_e_f").text();
        // отправляем данные
        $.ajax({
            url: "form/form_r.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "datepickerq": 	datepickerq,
                "count_daysq": 	count_daysq,
                "guest2q": 	guest2q,
                "gifts2q": 	gifts2q,
                "taxi2q": 	taxi2q,
                "grumm2q": 	grumm2q,
                "fotovideo2q": 	fotovideo2q,
                "resq": 	resq,
                "user_name": 	user_name,
                "user_phone": 	user_phone
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-reserve').html(data.result); // выводим ответ сервера
            }
        });
    });

    $('#btn_submit_oneclick').click(function(){
        // собираем данные с формы
        var user_title_oneclick 	    = $('#user_title_oneclick').val();
        var user_name_oneclick 	    = $('#user_name_oneclick').val();
        var user_phone_oneclick 	    = $('#user_phone_oneclick').val();
        // отправляем данные
        $.ajax({
            url: "form/form_r-oc.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_title_oneclick": 	user_title_oneclick,
                "user_name_oneclick": 	user_name_oneclick,
                "user_phone_oneclick": 	user_phone_oneclick
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-oneclick').html(data.result); // выводим ответ сервера
            }
        });
    });
});